import { ReactNode, useState } from "react";
import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import Link from "next/link";

import { Combobox } from "@headlessui/react";
import { ScrollAreaCombobox } from "./ScrollArea";
import { CheckUnfilled, CloseUnfilled } from "./Icons";

type ComboboxInputProps =
    | {
          id: string | number;
          text?: string;
          link?: string;
      }
    | null
    | undefined;

export type ComboboxOptionsProps = {
    id: string | number;
    text?: string;
    link?: string;
};

type KeyProp = string | number | undefined;

export const ComboboxInput = ({
    icon,
    placeholder,
    options,
    disabled = false,
    value,
    handleChange,
}: {
    icon?: ReactNode;
    placeholder: string;
    options: ComboboxOptionsProps[];
    disabled?: boolean;
    value?: string | null;
    handleChange: (v?: string) => void;
}): JSX.Element => {
    let initialValue: ComboboxInputProps = null;
    initialValue = options.find((o) => o.id == value);
    const [selected, setSelected] = useState<KeyProp>(
        initialValue?.id ?? undefined,
    );
    const [query, setQuery] = useState("");
    const comboValue = selected ?? query;

    const { t } = useTranslation("common");
    const filteredOptions: ComboboxOptionsProps[] =
        query === ""
            ? options
            : options.filter((o) =>
                  o.text
                      ?.toLowerCase()
                      .replace(/\s+/g, "")
                      .includes(query.toLowerCase().replace(/\s+/g, "")),
              );

    const onClear = (): void => {
        setQuery("");
        setSelected(undefined);
        handleChange("");
    };

    const onChange = (v: KeyProp): void => {
        if (v?.toString().trim().length === 0) {
            setQuery("");
            setSelected(undefined);
            handleChange("");
            return;
        }
        setSelected(v);
        v && handleChange(v.toString());
    };
    let triggerColor = "text-blue-grey-400 placeholder:text-blue-grey-400";
    if (disabled)
        triggerColor = "text-blue-grey-300 placeholder:text-blue-grey-300";
    else if (comboValue)
        triggerColor = "text-blue-grey-900 placeholder:text-blue-grey-900";

    let child = (
        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
            {t("nothingFound", "Nothing found.")}
        </div>
    );

    if (filteredOptions.length !== 0)
        child = (
            <>
                <Combobox.Option
                    value=""
                    className={({ active }) => cx(active && "bg-primary")}
                >
                    <span className="block h-0 w-full"></span>
                </Combobox.Option>
                {filteredOptions.map((o) => (
                    <Combobox.Option
                        key={o.id}
                        className={({ active }) =>
                            cx(
                                "typography-sub relative flex cursor-default select-none pl-10 pr-4 text-blue-grey-900",
                                active && "bg-primary-100",
                            )
                        }
                        value={o.id}
                    >
                        {({ selected }) => (
                            <>
                                <TextLink text={o.text ?? ""} link={o.link} />
                                {selected && (
                                    <span
                                        className={cx(
                                            "absolute inset-y-0 right-0 flex items-center pr-4 text-blue-grey-600",
                                        )}
                                    >
                                        <CheckUnfilled
                                            className="size-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                )}
                            </>
                        )}
                    </Combobox.Option>
                ))}
            </>
        );

    return (
        <Combobox
            value={comboValue}
            onChange={onChange}
            disabled={disabled}
            nullable
        >
            <div className="relative mt-1">
                <div
                    className={cx(
                        "flex h-10 items-center gap-3 rounded-lg border border-solid border-blue-grey-50 bg-white px-3 py-2",
                        disabled && "border-blue-grey-50 bg-blue-grey-20",
                    )}
                >
                    {icon}
                    <Combobox.Button className="flex w-full items-center border-none bg-transparent p-0 outline-none">
                        {({ open }) => (
                            <>
                                <Combobox.Input
                                    autoComplete="off"
                                    className={cx(
                                        "typography-main w-full border-none p-0 outline-none",
                                        triggerColor,
                                    )}
                                    placeholder={placeholder}
                                    displayValue={(id: KeyProp) =>
                                        options.find((opt) => opt.id == id)
                                            ?.text ?? query
                                    }
                                    onChange={(event) => {
                                        setQuery(event.target.value);
                                        handleChange(event.target.value);
                                    }}
                                    onClick={(e) => {
                                        if (open) e.stopPropagation();
                                    }}
                                />
                            </>
                        )}
                    </Combobox.Button>
                    {comboValue && (
                        <CloseUnfilled
                            className="text-blue-grey-900"
                            onClick={() => onClear()}
                        />
                    )}
                </div>
                <Combobox.Options className="typgoraphy-sub absolute mt-1 flex w-full overflow-auto rounded-lg bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <ScrollAreaCombobox className="max-h-60 max-w-full flex-1">
                        {child}
                    </ScrollAreaCombobox>
                </Combobox.Options>
            </div>
        </Combobox>
    );
};

const TextLink = ({
    text,
    link,
}: {
    text: string;
    link?: string;
}): JSX.Element => {
    const child = <span className="block flex-1 truncate py-2">{text}</span>;
    if (link) return <Link href={link}>{child}</Link>;
    return child;
};
