export const CentreCardSkeleton = (): JSX.Element => (
    <li className="flex animate-pulse flex-col overflow-hidden rounded-xl border-[0.5px] border-solid border-blue-grey-100 bg-white transition-all">
        <div className="relative min-h-[140px] w-full flex-1 bg-blue-grey-50" />
        <section className="flex h-[190px] max-w-full flex-1 flex-col justify-between gap-2 p-4">
            <div className="flex flex-col justify-between gap-2">
                <div className="h-3 w-32 rounded bg-blue-grey-50" />
                <div className="h-6 rounded bg-blue-grey-50" />
                <div className="h-4 rounded bg-blue-grey-50" />
                <div className="h-4 w-16 rounded bg-blue-grey-50" />
            </div>
            <div className="grid grid-cols-2 gap-2">
                <div className="h-10 rounded bg-blue-grey-50" />
                <div className="h-10 rounded bg-blue-grey-50" />
            </div>
        </section>
    </li>
);
