import { ReactNode, forwardRef, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
    Controller,
    FieldErrors,
    SubmitHandler,
    useForm,
    UseFormClearErrors,
    useFormContext,
    useWatch,
} from "react-hook-form";
import { Combobox } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import Link from "next/link";
import { Button } from "components/Button";
import { DatePicker } from "components/CalendarNew";
import { DropdownSelect } from "components/DropdownSelect";
import { CategoryList } from "components/CategoryList";
import { Separator } from "components/Separator";
import { ScrollArea, ScrollAreaCombobox } from "components/ScrollArea";
import {
    CalendarUnfilled,
    CheckUnfilled,
    CloseUnfilled,
    LocationUnfilled,
    OfficeBuildingUnfilled,
    SearchUnfilled,
} from "components/Icons";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as Dialog from "@radix-ui/react-dialog";
import { graphql } from "lib/gql";
import { SearchBarQuery } from "lib/gql/graphql";
import { isEqual } from "date-fns";
import { byStateImportance } from "utils/index";
import { format, formatInTimeZone, toDate } from "date-fns-tz";
import postcodesToState from "assets/lookups/states";
import { cx } from "class-variance-authority";
import {
    DateTimeFormValue,
    FormValues,
    constructDateRange,
    dateInputOptions,
} from "../SearchPage";
import { TFunction } from "i18next";
import { Input } from "components/Forms";

const timezone = "Asia/Kuala_Lumpur";
const query = graphql(`
    query searchBar {
        courtsitePartners {
            uid
            name
            ...CategoryListVenues
        }
        categories {
            uid
            name
            ...CategoryListCategories
        }
        locations {
            uid
            name
            city
            coordinates {
                latitude
                longitude
            }
        }
    }
`);

type SearchBarFormProps = {
    className?: string;
    onSubmit: SubmitHandler<FormValues>;
};
type StartEndFormProps = {
    values?: DateTimeFormValue;
    setErrorMsg: (v: string | undefined) => void;
    open: boolean;
    onOpenChange: (v: boolean) => void;
    onChangeValues: (v: DateTimeFormValue) => void;
    t: TFunc;
};
type StartEndInputProps = {
    value?: DateTimeFormValue;
    onChange: (v: DateTimeFormValue) => void;
    errors?: FieldErrors<DateTimeFormValue>;
    clearErrors: UseFormClearErrors<FormValues>;
    t: TFunc;
};
type TFunc = TFunction<["components/SearchPage", "common"]>;

export const SearchBarForm = ({
    className,
    onSubmit,
}: SearchBarFormProps): JSX.Element => {
    const { data } = useQuery(query, { fetchPolicy: "cache-and-network" });
    const {
        control,
        formState: { errors },
        setError,
        clearErrors,
        handleSubmit,
        resetField,
    } = useFormContext<FormValues>();
    const { t } = useTranslation(["components/SearchPage", "common"]);
    const venues = data?.courtsitePartners ?? [];

    const groupedLocationsByState = useMemo(() => {
        const locations = data?.locations ?? [];
        const groupedLocations = {};
        locations.forEach((l) => {
            let stateKey = l.city ?? "UNKNOWN";
            if (!l.city) {
                const matchedState = postcodesToState.find((state) =>
                    state.city.some((c) => c.name === l.name),
                );
                stateKey = matchedState?.name ?? "UNKNOWN";
            }

            if (!groupedLocations[stateKey]) groupedLocations[stateKey] = [];
            groupedLocations[stateKey]?.push(l);
        });

        return Object.keys(groupedLocations)
            .map((state) => {
                return {
                    state: state,
                    locations: groupedLocations[state],
                };
            })
            .filter((l) => l.state !== "UNKNOWN");
    }, [data?.locations]);

    const venueOptions: ComboboxOptionsProps[] = venues
        .map((v) => ({
            id: v.uid,
            text: v.name,
            link: `/centre/${v.name}/${v.uid}`,
        }))
        .sort((a, b) =>
            a.text.toLowerCase().localeCompare(b.text.toLowerCase()),
        );

    const locationOptions: ComboboxOptionsProps[] = groupedLocationsByState
        .sort(byStateImportance)
        .flatMap((l) =>
            l.locations.map((c) => ({
                id: c.uid,
                text: `${c.name}, ${l.state}`,
            })),
        );

    const defaultLocationOptions: DefaultLocationOptions[] =
        groupedLocationsByState
            .map((l) => ({
                state: l.state,
                options: l.locations.map((c) => ({
                    id: c.uid,
                    text: c.name,
                })),
            }))
            .sort(byStateImportance);
    const categoryId = useWatch({ control, name: "categoryId" });
    const locationId = useWatch({ control, name: "locationId" });
    const disableDate = !(!!categoryId || !!locationId);

    let errorMsg: string | undefined;
    if (
        errors.dtrange?.time ||
        errors.dtrange?.meridiem ||
        errors.dtrange?.duration
    ) {
        errorMsg = "Select time & duration";
    }
    const validateOnSubmit = (v: FormValues): void => {
        if (!v.dtrange?.date) {
            onSubmit(v);
            return;
        }
        const { date, time, meridiem, duration } = v.dtrange;
        if (!time) setError("dtrange.time", { message: "Required" });
        if (!meridiem) setError("dtrange.meridiem", { message: "Required" });
        if (!duration) setError("dtrange.duration", { message: "Required" });
        if (date && time && meridiem && duration) onSubmit(v);
    };

    return (
        <div className={cx("hidden justify-center lg:flex", className)}>
            <form
                onSubmit={handleSubmit(validateOnSubmit)}
                className="relative max-h-[120px] w-full max-w-[1056px] rounded-[60px] bg-white py-4 pl-10 pr-32 shadow-lg"
            >
                <div className="grid w-full grid-cols-[200px_1px_300px_1px_300px] items-start gap-4">
                    <Controller
                        control={control}
                        name="categoryId"
                        render={({ field: { onChange, value } }) => (
                            <CategoryPopover
                                data={data}
                                value={value}
                                onChange={(v) => {
                                    if (!v && !locationId) {
                                        resetField("dtrange");
                                    }
                                    onChange(v);
                                }}
                                t={t}
                            />
                        )}
                    />
                    <Separator
                        orientation="vertical"
                        color="dark"
                        className="mt-5 !h-8"
                    />
                    <Controller
                        control={control}
                        name="locationId"
                        render={({ field: { onChange, value, disabled } }) => (
                            <SearchCellLocations disabled={disabled} t={t}>
                                <LocationComboboxInput
                                    disabled={disabled}
                                    placeholder={t(
                                        "subtitleLocation",
                                        "Search venue name, city, or state",
                                    )}
                                    locationOptions={locationOptions}
                                    venueOptions={venueOptions}
                                    defaultLocationOptions={
                                        defaultLocationOptions
                                    }
                                    value={value}
                                    handleChange={(v) => {
                                        if (!v && !categoryId) {
                                            resetField("dtrange", {
                                                defaultValue: {
                                                    date: null,
                                                    time: null,
                                                    meridiem: null,
                                                    duration: null,
                                                },
                                            });
                                        }
                                        onChange(v);
                                    }}
                                    t={t}
                                />
                            </SearchCellLocations>
                        )}
                    />
                    <Separator
                        orientation="vertical"
                        color="dark"
                        className="mt-5 !h-8"
                    />
                    <Controller
                        control={control}
                        name="dtrange"
                        disabled={disableDate}
                        render={({ field: { onChange, value, disabled } }) => (
                            <div>
                                <DateTimePopover
                                    value={value}
                                    onChange={onChange}
                                    disabled={disabled}
                                    t={t}
                                    errors={errors.dtrange}
                                    clearErrors={clearErrors}
                                />
                                <div
                                    key={errorMsg}
                                    data-show={!!errorMsg}
                                    className="typography-tiny border-0 border-t border-solid pt-1 text-left font-bold text-destructive-600 data-[show=true]:block data-[show=false]:hidden data-[show=false]:border-transparent data-[show=true]:border-destructive"
                                >
                                    {errorMsg}
                                </div>
                            </div>
                        )}
                    />
                </div>
                <Button className="typography-main absolute bottom-6 end-4 top-6 flex h-[52px] w-[104px] gap-2 rounded-[30px] font-bold">
                    <SearchUnfilled className="size-6 text-white" />
                    {t("common:search", "Search")}
                </Button>
            </form>
        </div>
    );
};
type SearchBarControllerProps = {
    values: FormValues;
    handleSubmit: (type: SubmitType, value: SubmitValue) => void;
};

type SubmitType = "category" | "location" | "dtrange" | "distance";
type SubmitValue = DateTimeFormValue | string | number | null;

export const SearchBarController = ({
    values,
    handleSubmit,
}: SearchBarControllerProps): JSX.Element => {
    const { data } = useQuery(query);

    const { t } = useTranslation(["components/SearchPage", "common"]);
    const venues = data?.courtsitePartners ?? [];

    const groupedLocationsByState = useMemo(() => {
        const locations = data?.locations ?? [];
        const groupedLocations = {};
        locations.forEach((l) => {
            let stateKey = l.city ?? "UNKNOWN";
            if (!l.city) {
                const matchedState = postcodesToState.find((state) =>
                    state.city.some((c) => c.name === l.name),
                );
                stateKey = matchedState?.name ?? "UNKNOWN";
            }

            if (!groupedLocations[stateKey]) groupedLocations[stateKey] = [];
            groupedLocations[stateKey]?.push(l);
        });

        return Object.keys(groupedLocations)
            .map((state) => {
                return {
                    state: state,
                    locations: groupedLocations[state],
                };
            })
            .filter((l) => l.state !== "UNKNOWN");
    }, [data?.locations]);

    const venueOptions: ComboboxOptionsProps[] = venues
        .map((v) => ({
            id: v.uid,
            text: v.name,
            link: `/centre/${v.name}/${v.uid}`,
        }))
        .sort((a, b) =>
            a.text.toLowerCase().localeCompare(b.text.toLowerCase()),
        );

    const locationOptions: ComboboxOptionsProps[] = groupedLocationsByState
        .sort(byStateImportance)
        .flatMap((l) =>
            l.locations.map((c) => ({
                id: c.uid,
                text: `${c.name}, ${l.state}`,
            })),
        );
    const defaultLocationOptions: DefaultLocationOptions[] =
        groupedLocationsByState
            .map((l) => ({
                state: l.state,
                options: l.locations.map((c) => ({
                    id: c.uid,
                    text: c.name,
                })),
            }))
            .sort(byStateImportance);
    const categoryId = values.categoryId;
    const locationId = values.locationId;
    const disableDate = !(!!categoryId || !!locationId);

    return (
        <div className="hidden justify-center lg:flex">
            <div className="relative my-3 max-h-[120px] w-full max-w-[1056px] rounded-[60px] bg-white px-11 py-4 shadow-lg">
                <div className="grid w-full grid-cols-[300px_1px_300px_1px_300px] items-start gap-4">
                    <CategoryPopover
                        data={data}
                        value={categoryId}
                        onChange={(v) => {
                            if (!v && !locationId) {
                                handleSubmit("dtrange", {
                                    date: null,
                                    time: null,
                                    meridiem: null,
                                    duration: null,
                                });
                            }
                            handleSubmit("category", v ?? null);
                        }}
                        t={t}
                    />
                    <Separator
                        orientation="vertical"
                        color="dark"
                        className="mt-5 !h-8"
                    />
                    <SearchCellLocations t={t}>
                        <LocationComboboxInput
                            placeholder={t(
                                "subtitleLocation",
                                "Search venue name, city, or state",
                            )}
                            locationOptions={locationOptions}
                            venueOptions={venueOptions}
                            defaultLocationOptions={defaultLocationOptions}
                            value={locationId}
                            handleChange={(v) => {
                                if (!v?.trim() && !categoryId) {
                                    handleSubmit("dtrange", {
                                        date: null,
                                        time: null,
                                        meridiem: null,
                                        duration: null,
                                    });
                                }
                                handleSubmit("location", v ?? null);
                            }}
                            t={t}
                        />
                    </SearchCellLocations>
                    <Separator
                        orientation="vertical"
                        color="dark"
                        className="mt-5 !h-8"
                    />
                    <DateTimePopoverForm
                        values={values.dtrange}
                        onChangeValues={(v) => handleSubmit("dtrange", v)}
                        disabled={disableDate}
                        t={t}
                    />
                </div>
            </div>
        </div>
    );
};

const SearchCell = ({
    title,
    subtitle,
    value,
    disabled,
    error,
    onClear,
}: {
    title: string;
    subtitle: string;
    value?: string | null;
    disabled?: boolean;
    error?: boolean;
    onClear: () => void;
}): JSX.Element => {
    let triggerColor = "text-blue-grey-400 placeholder:text-blue-grey-400";
    if (disabled)
        triggerColor = "text-blue-grey-100 placholder:text-blue-grey-100";
    else if (value)
        triggerColor = "text-blue-grey-900 placeholder:text-blue-grey-900";
    return (
        <div
            className={cx(
                "my-2 flex h-[52px] items-center gap-3",
                disabled ? "cursor-not-allowed" : "cursor-pointer",
            )}
        >
            <div className="w-full">
                <div
                    className={cx(
                        "typography-main mb-0.5 text-left font-bold",
                        disabled && !error
                            ? "text-blue-grey-100"
                            : "text-blue-grey-900",
                        error && "text-destructive",
                    )}
                >
                    {title}
                    {error && " *"}
                </div>
                <div
                    className={cx(
                        "typography-main flex w-full flex-nowrap items-center justify-between text-nowrap text-left",
                        triggerColor,
                    )}
                >
                    {value ?? subtitle}
                    {value && (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                onClear();
                            }}
                            className="m-0 flex size-6 cursor-pointer items-center justify-center border-none bg-transparent p-0"
                        >
                            <CloseUnfilled className="size-4" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const SearchCellLocations = ({
    children,
    disabled,
    t,
}: {
    children: ReactNode;
    disabled?: boolean;
    t: TFunc;
}): JSX.Element => {
    let triggerColor = "text-blue-grey-400 placeholder:text-blue-grey-400";
    if (disabled)
        triggerColor = "text-blue-grey-100 placeholder:text-blue-grey-100";
    return (
        <div
            className={cx(
                "flex items-center gap-3 py-2",
                disabled ? "cursor-not-allowed" : "cursor-pointer",
            )}
        >
            <div className="w-full">
                <div
                    className={cx(
                        "typography-main mb-1 text-left font-bold",
                        disabled ? "text-blue-grey-100" : "text-blue-grey-900",
                    )}
                >
                    {t("titleLocation", "Where")}
                </div>
                <div
                    className={cx(
                        "typography-main flex w-full flex-nowrap items-center justify-between text-nowrap text-left",
                        triggerColor,
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

const CategoryPopover = ({
    data,
    disabled,
    value,
    onChange,
    t,
}: {
    data?: SearchBarQuery;
    disabled?: boolean;
    value?: string | null;
    onChange: (v: FormValues["categoryId"]) => void;
    t: TFunc;
}): JSX.Element => {
    const [open, setOpen] = useState(false);
    const text =
        data?.categories.find((c) => c.uid == value)?.name ?? undefined;

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>
                <div>
                    <SearchCell
                        title={t("titleCategory", "Sport")}
                        subtitle={t("subtitleCategory", "Select a sport")}
                        value={text}
                        disabled={disabled}
                        onClear={() => onChange(null)}
                    />
                </div>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 z-20 bg-black/40" />
                <Dialog.Content className={dialogCss}>
                    <Dialog.Description className="flex h-[600px]">
                        <ScrollArea className="flex w-full flex-1">
                            <div className="w-full pl-1 pr-3">
                                <CategoryList
                                    categoriesFragment={data?.categories}
                                    venuesFragment={data?.courtsitePartners}
                                    type="input"
                                    value={value}
                                    onChange={(v) => {
                                        onChange(v ?? null);
                                        setOpen(false);
                                    }}
                                />
                            </div>
                        </ScrollArea>
                    </Dialog.Description>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

const desktopDialog =
    "lg:inset-1/2 lg:h-max lg:max-h-[80%] lg:max-w-[628px] lg:-translate-x-1/2 lg:-translate-y-1/2 lg:gap-8 lg:rounded-xl lg:transition-none lg:data-[state=closed]:fade-out-0 lg:data-[state=open]:fade-in-0 lg:data-[state=closed]:zoom-out-95 lg:data-[state=open]:zoom-in-95 lg:data-[state=closed]:slide-out-to-left-1/2 lg:data-[state=closed]:slide-out-to-top-[48%] lg:data-[state=open]:slide-in-from-left-1/2 lg:data-[state=open]:slide-in-from-top-[48%]";
const dialogCss = cx(
    "fixed z-20 flex w-dvw flex-col gap-4 overflow-y-auto bg-white p-4 py-8 transition ease-in-out data-[state=closed]:duration-400 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out",
    desktopDialog,
);

const DateTimePopover = ({
    value,
    onChange,
    disabled,
    t,
    errors,
    clearErrors,
}: {
    value?: DateTimeFormValue;
    onChange: (v: DateTimeFormValue) => void;
    disabled?: boolean;
    t: TFunc;
    errors?: FieldErrors<DateTimeFormValue>;
    clearErrors: UseFormClearErrors<FormValues>;
}): JSX.Element => {
    let text: string | undefined;
    const { startDt, endDt } = constructDateRange(value);
    if (startDt) {
        let startDate = formatInTimeZone(startDt, timezone, "dd/MM/yy");
        let endDate: string | undefined;
        text = startDate;
        if (endDt && !isEqual(startDt, endDt)) {
            startDate = formatInTimeZone(startDt, timezone, "dd/MM/yy hh:mma");
            endDate = formatInTimeZone(endDt, timezone, " - hh:mma");
            text = `${startDate}${endDate}`;
        }
    }

    return (
        <Popover>
            <PopoverTrigger disabled={disabled} className="w-full flex-1 p-0">
                <SearchCell
                    key={text}
                    title={t("titleDate", "When")}
                    subtitle={t("subtitleDate", "Pick a date")}
                    value={text}
                    disabled={disabled}
                    error={
                        !!errors?.time ||
                        !!errors?.meridiem ||
                        !!errors?.duration
                    }
                    onClear={() => {
                        onChange({
                            date: null,
                            time: null,
                            meridiem: null,
                            duration: null,
                        });
                        clearErrors(["dtrange"]);
                    }}
                />
            </PopoverTrigger>
            <PopoverContent
                align="start"
                side="bottom"
                alignOffset={-15}
                className="w-[385px] p-4"
                key={text}
            >
                <StartEndInput
                    value={value}
                    onChange={onChange}
                    errors={errors}
                    clearErrors={clearErrors}
                    t={t}
                />
            </PopoverContent>
        </Popover>
    );
};

type DateTimePopoverFormValue = {
    dtrange: DateTimeFormValue;
};

const DateTimePopoverForm = ({
    values,
    onChangeValues,
    disabled,
    t,
}: {
    values?: DateTimeFormValue;
    onChangeValues: (v: DateTimeFormValue) => void;
    disabled: boolean;
    t: TFunc;
}): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();

    let text: string | undefined;
    const { startDt, endDt } = constructDateRange(values);
    if (startDt) {
        let startDate = formatInTimeZone(startDt, timezone, "dd/MM/yy");
        let endDate: string | undefined;
        text = startDate;
        if (endDt && !isEqual(startDt, endDt)) {
            startDate = formatInTimeZone(startDt, timezone, "dd/MM/yy hh:mma");
            endDate = formatInTimeZone(endDt, timezone, " - hh:mma");
            text = `${startDate}${endDate}`;
        }
    }

    const handleOpenChange = (v: boolean): void => {
        if (!v) setErrorMsg(undefined);
        setOpen(v);
    };

    return (
        <div>
            <Popover open={open} onOpenChange={handleOpenChange}>
                <PopoverTrigger
                    disabled={disabled}
                    className="w-full flex-1 p-0"
                >
                    <SearchCell
                        key={text}
                        title={t("titleDate", "When")}
                        subtitle={t("subtitleDate", "Pick a date")}
                        value={text}
                        disabled={disabled}
                        error={!!errorMsg}
                        onClear={() => {
                            onChangeValues({
                                date: null,
                                time: null,
                                meridiem: null,
                                duration: null,
                            });
                        }}
                    />
                </PopoverTrigger>
                <PopoverContent
                    align="end"
                    side="bottom"
                    alignOffset={-15}
                    className="w-[385px] p-4"
                    key={text}
                >
                    <StartEndForm
                        values={values}
                        setErrorMsg={setErrorMsg}
                        open={open}
                        onOpenChange={handleOpenChange}
                        onChangeValues={onChangeValues}
                        t={t}
                    />
                </PopoverContent>
            </Popover>
            <div
                key={errorMsg}
                data-show={!!errorMsg}
                className="typography-tiny border-0 border-t border-solid pt-1 text-left font-bold text-destructive-600 data-[show=true]:block data-[show=false]:hidden data-[show=false]:border-transparent data-[show=true]:border-destructive"
            >
                {errorMsg}
            </div>
        </div>
    );
};

const StartEndForm = ({
    values,
    setErrorMsg,
    open,
    onOpenChange,
    onChangeValues,
    t,
}: StartEndFormProps): JSX.Element => {
    const {
        control,
        formState: { errors },
        setError,
        clearErrors,
        resetField,
        handleSubmit,
    } = useForm<DateTimePopoverFormValue>({
        defaultValues: { dtrange: values },
    });

    const onSubmit: SubmitHandler<DateTimePopoverFormValue> = (v) => {
        if (!v.dtrange.date) return;
        const { date, time, meridiem, duration } = v.dtrange;
        if (date && !time) setError("dtrange.time", { message: "Required" });
        if (date && !meridiem)
            setError("dtrange.meridiem", { message: "Required" });
        if (date && !duration)
            setError("dtrange.duration", { message: "Required" });
        if (date && time && meridiem && duration) {
            onChangeValues(v.dtrange);
            onOpenChange(false);
        }
    };

    const errorDt = errors.dtrange;
    if (open && (errorDt?.time || errorDt?.meridiem || errorDt?.duration))
        setErrorMsg("Select time & duration");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="dtrange"
                render={({ field: { onChange, value } }) => (
                    <StartEndInput
                        value={value}
                        onChange={onChange}
                        errors={errorDt}
                        clearErrors={clearErrors}
                        t={t}
                    />
                )}
            />
            <div className="mt-5 grid grid-cols-2 gap-3">
                <Button
                    variant="outlined"
                    size="lg"
                    onClick={() => {
                        onChangeValues({
                            date: null,
                            time: null,
                            meridiem: null,
                            duration: null,
                        });
                        resetField("dtrange");
                        setErrorMsg(undefined);
                    }}
                    className="w-full"
                >
                    {t("common:clear", "Clear")}
                </Button>
                <Button type="submit" size="lg" className="w-full">
                    {t("common:apply", "Apply")}
                </Button>
            </div>
        </form>
    );
};

const StartEndInput = ({
    value,
    onChange,
    errors,
    clearErrors,
    t,
}: StartEndInputProps): JSX.Element => {
    const { date, time, meridiem, duration } = value ?? {
        date: null,
        time: null,
        meridiem: null,
        duration: null,
    };

    const handleDateChange = (v: DateTimeFormValue): void => {
        const { timeOptions, meridiemOptions } = dateInputOptions(t, v.date);

        const defaultValueTime =
            timeOptions.length === 1 ? timeOptions[0]?.value : undefined;
        const defaultValueMeridiem =
            meridiemOptions.length === 1
                ? meridiemOptions[0]?.value
                : undefined;

        onChange({
            ...v,
            time: defaultValueTime ?? v.time,
            meridiem: defaultValueMeridiem ?? v.meridiem,
        });
    };

    const { timeOptions, meridiemOptions, durationOptions } = dateInputOptions(
        t,
        date,
        time,
    );

    const selectedDate = date ? toDate(date) : undefined;
    return (
        <div className="flex flex-1 flex-col gap-3">
            <div className="typography-h6 font-bold text-blue-grey-900">
                {t("common:date", "Date")}
            </div>
            <DatePicker
                selected={selectedDate}
                onSelect={(e) => {
                    if (e !== selectedDate) {
                        const formattedDate =
                            (e && format(e, "yyyy-MM-dd")) ?? null;
                        handleDateChange({
                            date: formattedDate,
                            time: null,
                            meridiem: null,
                            duration: null,
                        });
                        clearErrors(["dtrange"]);
                    }
                }}
                disabled={{ before: new Date() }}
            >
                <Input
                    key={selectedDate?.toISOString()}
                    placeholder={t("common:date", "Date")}
                    defaultValue={
                        selectedDate &&
                        format(selectedDate, "dd MMM yyyy, eeee")
                    }
                    suffix={
                        <CalendarUnfilled className="size-5 text-blue-grey-200" />
                    }
                />
            </DatePicker>
            <div className="typography-h6 font-bold text-blue-grey-900">
                {t("timeDuration", "Start time & duration")}
            </div>
            <div className="flex gap-1">
                <div className="w-28">
                    <DropdownSelect
                        key={time}
                        disabled={!date && !time}
                        value={time ?? undefined}
                        onValueChange={(e) => {
                            onChange({ ...value, time: e });
                            clearErrors(["dtrange"]);
                        }}
                        options={timeOptions}
                        placeholder="00:00"
                    />
                    <ErrorDiv message={errors?.time?.message} />
                </div>
                <div className="w-20">
                    <DropdownSelect
                        key={meridiem}
                        disabled={!time && !meridiem}
                        value={meridiem ?? undefined}
                        onValueChange={(e) => {
                            onChange({ ...value, meridiem: e });
                            clearErrors(["dtrange"]);
                        }}
                        options={meridiemOptions}
                        placeholder={t("common:AM")}
                    />
                    <ErrorDiv message={errors?.meridiem?.message} />
                </div>
                <div className="grow">
                    <DropdownSelect
                        key={duration}
                        disabled={!meridiem}
                        value={duration ?? undefined}
                        onValueChange={(e) => {
                            onChange({ ...value, duration: e });
                            clearErrors(["dtrange"]);
                        }}
                        options={durationOptions}
                        placeholder={t("common:hour", "{{count}} Hour", {
                            count: 1,
                        })}
                    />
                    <ErrorDiv message={errors?.duration?.message} />
                </div>
            </div>
        </div>
    );
};

const ErrorDiv = ({ message }: { message?: string }): JSX.Element => {
    if (!message) return <div />;
    return (
        <div className="typography-tiny pl-1 font-bold text-destructive-600">
            {message}
        </div>
    );
};

type ComboboxInputProps =
    | {
          id: string | number;
          text?: string;
          link?: string;
      }
    | null
    | undefined;

type ComboboxOptionsProps = {
    id: string | number;
    text?: string;
    link?: string;
};

type KeyProp = string | number | undefined;

type DefaultLocationOptions = {
    state: string;
    options: ComboboxOptionsProps[];
};
const LocationComboboxInput = ({
    icon,
    placeholder,
    locationOptions,
    venueOptions,
    defaultLocationOptions,
    disabled = false,
    value,
    handleChange,
    t,
}: {
    icon?: ReactNode;
    placeholder: string;
    locationOptions: ComboboxOptionsProps[];
    venueOptions: ComboboxOptionsProps[];
    defaultLocationOptions: DefaultLocationOptions[];
    disabled?: boolean;
    value?: string | null;
    handleChange: (v?: string) => void;
    t: TFunc;
}): JSX.Element => {
    const currentLocation = {
        id: "current_location",
        text: t("common:useCurrentLocation", "Use current location"),
    };
    const combineOptions = [
        currentLocation,
        ...venueOptions,
        ...locationOptions,
    ];
    let initialValue: ComboboxInputProps = null;
    initialValue = combineOptions.find((o) => o.id === value);
    const [selected, setSelected] = useState<KeyProp>(
        initialValue?.id ?? undefined,
    );
    const [query, setQuery] = useState("");
    const comboValue = selected ?? query;

    if (initialValue?.id !== selected) setSelected(initialValue?.id);

    const filteredOptions: ComboboxOptionsProps[] =
        query === ""
            ? combineOptions
            : combineOptions.filter((o) =>
                  o.text
                      ?.toLowerCase()
                      .replace(/\s+/g, "")
                      .replace(/[^\w\s]|_/g, "")
                      .includes(
                          query
                              .toLowerCase()
                              .replace(/\s+/g, "")
                              .replace(/[^\w\s]|_/g, ""),
                      ),
              );

    const filteredOptionsId = filteredOptions.map((o) => o.id);
    const onClear = (): void => {
        setQuery("");
        setSelected(undefined);
        handleChange("");
    };

    const onChange = (v: KeyProp): void => {
        setQuery(""); // reset query everytime an option is selected
        if (v?.toString().trim().length === 0) {
            setSelected(undefined);
            handleChange("");
            return;
        }
        setSelected(v);
        v && handleChange(v.toString());
    };
    let triggerColor = "text-blue-grey-400 placeholder:text-blue-grey-400";
    if (disabled)
        triggerColor = "text-blue-grey-100 placeholder:text-blue-grey-100";
    else if (comboValue)
        triggerColor = "text-blue-grey-900 placeholder:text-blue-grey-900";

    let child = (
        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
            {t("common:nothingFound", "Nothing found.")}
        </div>
    );

    const filteredVenueOptions = venueOptions.filter((o) =>
        filteredOptionsId.includes(o.id),
    );
    const filteredLocationOptions = locationOptions.filter((o) =>
        filteredOptionsId.includes(o.id),
    );

    if (filteredOptions.length !== 0 && comboValue) {
        child = (
            <>
                <PermanentOptions {...currentLocation} />
                <div className="typography-h5 cursor-default py-2 pl-3 font-semibold text-blue-grey-900 group-data-[location='0']:hidden">
                    {t("common:location", "Location")}
                </div>
                {filteredLocationOptions.map((o) => (
                    <ComboboxOption key={o.id} o={o} type="location" />
                ))}
                <div className="cursor-default px-3 py-2 group-data-[venue='0']:hidden">
                    <Separator orientation="horizontal" color="dark" />
                </div>
                <div className="typography-h5 cursor-default py-2 pl-3 font-semibold text-blue-grey-900 group-data-[venue='0']:hidden">
                    {t("common:venues", "Venues")}
                </div>
                {filteredVenueOptions.map((o) => (
                    <ComboboxOption key={o.id} o={o} type="venue" />
                ))}
            </>
        );
    } else if (!comboValue) {
        child = (
            <>
                <PermanentOptions {...currentLocation} />
                {defaultLocationOptions.map((s) => (
                    <div key={s.state}>
                        <span className="typography-h5 cursor-default py-2 pl-3 font-semibold text-blue-grey-900">
                            {s.state}
                        </span>
                        {s.options.map((o) => (
                            <ComboboxOption key={o.id} o={o} type="location" />
                        ))}
                    </div>
                ))}
            </>
        );
    }

    return (
        <Combobox
            value={comboValue}
            onChange={onChange}
            disabled={disabled}
            nullable
        >
            <div className="relative w-full">
                <div className="flex h-6 items-center gap-3 bg-white">
                    {icon}
                    <Combobox.Button className="flex w-full items-center border-none bg-transparent p-0 outline-none">
                        {({ open }) => (
                            <>
                                <Combobox.Input
                                    autoComplete="off"
                                    className={cx(
                                        "typography-main w-full border-none p-0 outline-none",
                                        triggerColor,
                                        disabled && "bg-white",
                                    )}
                                    placeholder={placeholder}
                                    displayValue={(id: KeyProp) =>
                                        combineOptions.find(
                                            (opt) => opt.id == id,
                                        )?.text ?? query
                                    }
                                    onChange={(event) => {
                                        setQuery(event.target.value);
                                        handleChange(event.target.value);
                                    }}
                                    onClick={(e) => {
                                        if (open) e.stopPropagation();
                                    }}
                                />
                            </>
                        )}
                    </Combobox.Button>
                    {comboValue && (
                        <CloseUnfilled
                            className="text-blue-grey-900"
                            onClick={() => onClear()}
                        />
                    )}
                </div>
                <Combobox.Options
                    data-location={filteredLocationOptions.length}
                    data-venue={filteredVenueOptions.length}
                    className="typgoraphy-sub group absolute z-10 mt-7 flex w-full overflow-auto rounded-b-lg bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
                >
                    <ScrollAreaCombobox className="max-h-60 max-w-full flex-1">
                        {child}
                    </ScrollAreaCombobox>
                </Combobox.Options>
            </div>
        </Combobox>
    );
};

const PermanentOptions = (currentLocation: {
    id: string;
    text: string;
}): JSX.Element => (
    <>
        <Combobox.Option
            value=""
            className={({ active }) => cx(active && "bg-primary")}
        >
            <span className="block h-0 w-full"></span>
        </Combobox.Option>
        <Combobox.Option
            className={({ active }) =>
                cx(
                    "typography-sub relative flex cursor-default select-none pl-3 pr-4 text-primary-600",
                    active && "bg-primary-100",
                )
            }
            value={currentLocation.id}
        >
            {({ selected }) => (
                <>
                    <div className="py-2">{currentLocation.text}</div>
                    {selected && (
                        <span
                            className={cx(
                                "absolute inset-y-0 right-0 flex items-center pr-4 text-blue-grey-600",
                            )}
                        >
                            <CheckUnfilled
                                className="size-5"
                                aria-hidden="true"
                            />
                        </span>
                    )}
                </>
            )}
        </Combobox.Option>
    </>
);

const ComboboxOption = ({
    o,
    type,
}: {
    o: ComboboxOptionsProps;
    type: "venue" | "location";
}): JSX.Element => (
    <Combobox.Option
        key={o.id}
        className={({ active }) =>
            cx(
                "typography-sub relative flex cursor-default select-none pl-10 pr-4 text-blue-grey-900",
                active && "bg-primary-100",
            )
        }
        value={o.id}
    >
        {({ selected }) => (
            <>
                <span
                    className={cx(
                        "absolute inset-y-0 left-0 flex items-center pl-3 text-blue-grey-300",
                    )}
                >
                    {type === "venue" ? (
                        <OfficeBuildingUnfilled
                            className="size-5"
                            aria-hidden="true"
                        />
                    ) : (
                        <LocationUnfilled
                            className="size-5"
                            aria-hidden="true"
                        />
                    )}
                </span>
                <TextLink text={o.text ?? ""} link={o.link} />
                {selected && (
                    <span
                        className={cx(
                            "absolute inset-y-0 right-0 flex items-center pr-4 text-blue-grey-600",
                        )}
                    >
                        <CheckUnfilled className="size-5" aria-hidden="true" />
                    </span>
                )}
            </>
        )}
    </Combobox.Option>
);

const TextLink = ({
    text,
    link,
}: {
    text: string;
    link?: string;
}): JSX.Element => {
    const child = <span className="block flex-1 truncate py-2">{text}</span>;
    if (link) return <Link href={link}>{child}</Link>;
    return child;
};

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>(({ children, className, ...props }, ref) => (
    <PopoverPrimitive.Trigger
        ref={ref}
        className={cx("border-none bg-transparent", className)}
        {...props}
    >
        {children}
    </PopoverPrimitive.Trigger>
));
PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName;

const PopoverContent = forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "start", sideOffset = 0, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={cx(
                "relative z-50 rounded-xl border bg-white shadow-xl outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                className,
            )}
            {...props}
        />
    </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
